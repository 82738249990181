import { useEffect } from 'react';
import { navigate } from 'gatsby';

import { defaultLanguage } from '@/languages/config';

export default () => {
  useEffect(() => {
    navigate(`/${defaultLanguage}/press/`);
  }, []);
  return null;
};
